body {
  &[theme=dark] {
    .db-card {
      background: #1e242a;
    }
    .bb-timeline ul li .bb-info {
      border-color: #666;
    }
  }

  &[theme=black] {
    .db-card {
      background: #212121;
    }
    .bb-timeline ul li .bb-info {
      border-color: #ffffffbd;
    }
  }
}

.underline {
  border-bottom: 2px solid #dfb50b;
}

.zh-em-mark {
  text-emphasis: filled dot;
  -webbit-text-emphasis: filled dot;
  text-emphasis-position: under;
  -webkit-text-emphasis-position: under;
}

.header-title > a {
  display: flex;
  align-items: center;

  img {
    margin-right: 5px;
  }
}

.home-title {
  font-family: 'Noto Serif TC';
}

/**
 * Card styles modified from 
 * https://github.com/lmm214/immmmm/blob/d33b2b8e99f63b4ff4349f670e8ecf3177dbc654/themes/hello-friend/static/theme-20230215.css
 */
/* db-card -------- start */
.db-card {
  margin: 2rem 3rem;
  background: #fafafa;
  border-radius: 4px;
  box-shadow: 0 1px 2px RGB(0 0 0 / 25%), 0 0 1px RGB(0 0 0 / 25%);
}

.db-card-subject {
  display: flex;
  align-items: center;
  line-height: 1.6;
  padding: 12px;
}

.db-card-content {
  flex: 1 1 auto;
}

.db-card-post {
  width: 96px;
  margin-right: 15px;
}

.db-card-title {
  margin-bottom: 5px;
  font-size: 18px;
}

.db-card-abstract,
.db-card-comment {
  font-size: 14px;
}

.db-card-post img {
  display: block;
  width: 100%;
  height: 100px;
  border-radius: 4px;
  -o-object-fit: cover;
  object-fit: cover;
  object-position: center top;
  -o-object-position: center top;
}

.rating {
  margin: 0 0 5px;
  font-size: 13px;
  line-height: 1;
  display: flex;
  align-items: center;
}

.rating .allstardark {
  position: relative;
  color: #f99b01;
  height: 16px;
  width: 80px;
  background-size: auto 100%;
  margin-right: 8px;
  background-repeat: repeat;
  background-image: url(data:image/svg+xml;base64,PHN2ZyBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMzIiIGhlaWdodD0iMzIiPjxwYXRoIGQ9Ik05MDguMSAzNTMuMWwtMjUzLjktMzYuOUw1NDAuNyA4Ni4xYy0zLjEtNi4zLTguMi0xMS40LTE0LjUtMTQuNS0xNS44LTcuOC0zNS0xLjMtNDIuOSAxNC41TDM2OS44IDMxNi4ybC0yNTMuOSAzNi45Yy03IDEtMTMuNCA0LjMtMTguMyA5LjMtMTIuMyAxMi43LTEyLjEgMzIuOS42IDQ1LjNsMTgzLjcgMTc5LjEtNDMuNCAyNTIuOWMtMS4yIDYuOS0uMSAxNC4xIDMuMiAyMC4zIDguMiAxNS42IDI3LjYgMjEuNyA0My4yIDEzLjRMNTEyIDc1NGwyMjcuMSAxMTkuNGM2LjIgMy4zIDEzLjQgNC40IDIwLjMgMy4yIDE3LjQtMyAyOS4xLTE5LjUgMjYuMS0zNi45bC00My40LTI1Mi45IDE4My43LTE3OS4xYzUtNC45IDguMy0xMS4zIDkuMy0xOC4zIDIuNy0xNy41LTkuNS0zMy43LTI3LTM2LjN6TTY2NC44IDU2MS42bDM2LjEgMjEwLjNMNTEyIDY3Mi43IDMyMy4xIDc3MmwzNi4xLTIxMC4zLTE1Mi44LTE0OUw0MTcuNiAzODIgNTEyIDE5MC43IDYwNi40IDM4MmwyMTEuMiAzMC43LTE1Mi44IDE0OC45eiIgZmlsbD0iI2Y5OWIwMSIvPjwvc3ZnPg==);
}

.rating .allstarlight {
  position: absolute;
  left: 0;
  color: #f99b01;
  height: 16px;
  overflow: hidden;
  background-size: auto 100%;
  background-repeat: repeat;
  background-image: url(data:image/svg+xml;base64,PHN2ZyBjbGFzcz0iaWNvbiIgdmlld0JveD0iMCAwIDEwMjQgMTAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMzIiIGhlaWdodD0iMzIiPjxwYXRoIGQ9Ik05MDguMSAzNTMuMWwtMjUzLjktMzYuOUw1NDAuNyA4Ni4xYy0zLjEtNi4zLTguMi0xMS40LTE0LjUtMTQuNS0xNS44LTcuOC0zNS0xLjMtNDIuOSAxNC41TDM2OS44IDMxNi4ybC0yNTMuOSAzNi45Yy03IDEtMTMuNCA0LjMtMTguMyA5LjMtMTIuMyAxMi43LTEyLjEgMzIuOS42IDQ1LjNsMTgzLjcgMTc5LjEtNDMuNCAyNTIuOWMtMS4yIDYuOS0uMSAxNC4xIDMuMiAyMC4zIDguMiAxNS42IDI3LjYgMjEuNyA0My4yIDEzLjRMNTEyIDc1NGwyMjcuMSAxMTkuNGM2LjIgMy4zIDEzLjQgNC40IDIwLjMgMy4yIDE3LjQtMyAyOS4xLTE5LjUgMjYuMS0zNi45bC00My40LTI1Mi45IDE4My43LTE3OS4xYzUtNC45IDguMy0xMS4zIDkuMy0xOC4zIDIuNy0xNy41LTkuNS0zMy43LTI3LTM2LjN6IiBmaWxsPSIjZjk5YjAxIi8+PC9zdmc+);
}

@media (max-width: 550px) {
  .db-card {
    margin: 0.8rem 1rem;
  }

  .db-card-comment {
    display: none;
  }
}
/* db-card -------- end */

/* glightbox -------- start */
.glightbox-clean {
  .description-bottom {
    background: #000 !important;
    text-align: center;

    .gdesc-inner {
      padding: 0 20px;

      .gslide-title {
        color: #fff;
      }
    }
  }
}
/* glightbox -------- end */

/* Memos page -------- start */
#memos {
  font-family: 'Noto Serif SC', $global-font-family;
  margin-top: 2em;
  width: auto !important;
  min-height: 100vh;
}
.bb-timeline ul {
  margin: 0;
  padding: 0;

  li {
    &::before {
      content: none;
    }
    .datacont ul li {
      margin-bottom: 0;
    }
    .bb-div {
      padding: 0.6rem 1rem;
      border: 1px solid #666;
    }
    .bb-info {
      display: flex;
      justify-content: space-between;
      line-height: 28px;
      font-size: 14px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.35);
      padding-bottom: 8px;

      svg {
        vertical-align: sub;
      }
    }
    .datatime {
      letter-spacing: 1px;
    }
    .datacont {
      min-height: 88px;
      max-height: 50vh;
      overflow-y: scroll;
      overflow-x: hidden;
    }
    .datacont img[src*='emotion'] {
      display: inline-block;
      width: auto;
    }
    p {
      margin: 0;
      font-size: 16px;
      letter-spacing: 1px;
      line-height: 28px;
      min-height: 18px;
      margin: 0;
    }
  }
}
.bb-timeline > ul > li {
  list-style-type: none;
  margin-bottom: 2rem;
}
.bb-load button {
  font-size: 0.8rem;
  font-style: italic;
  background: none;
  border-radius: 0;
  border: 1px solid #666;
  padding: 10px 30px;
  width: 100%;
  letter-spacing: 0.8rem;
}
.bb-timeline pre p {
  display: inline-block;

  &:empty {
    display: none;
  }
}
.datacont {
  p {
    margin: 0;
  }
  blockquote {
    font-family: KaiTi, STKaiti, STFangsong !important;
    margin: 0 0 0 1rem;
    padding: 0.25rem 2rem;
    position: relative;
    border-left: 0 none;

    &::before {
      line-height: 2rem;
      content: '“';
      font-family: Georgia, serif;
      font-size: 28px;
      font-weight: bold;
      position: absolute;
      left: 10px;
      top: 5px;
    }
  }
  .tag-span {
    color: #42b983;
  }
  .img {
    cursor: pointer;
    border-radius: 4px;

    &.square {
      height: 180px;
      width: 180px;
      object-fit: cover;
    }
  }
}
#bb-footer {
  margin: 5rem auto 1rem;
  text-align: center;

  p {
    margin: 0 0 0.6rem;
  }
}
.bb-allnums {
  letter-spacing: 2px;
}
.bb-allpub {
  text-decoration: none;
  font-style: italic;
}
.video-wrapper {
  position: relative;
  padding-bottom: 55%;
  width: 100%;
  height: 0;
  
  iframe {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  video {
    max-height: 30vh;
  }
}
.item-waline {
  margin: 1rem 0;
}
.d-none {
  display: none !important;
}
/* Memos page -------- end */
